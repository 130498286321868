/* ContactForm.css */
.contact-form-container {
	position: relative;
    padding: 2rem;

	.background-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		opacity: 0.5; /* Adjust opacity as needed */
		z-index: -1; /* Place behind other content */
	}
}
