.banner-container {
	display: block;
	position: relative;
	min-height: 420px;

	.banner-image {
		position: absolute;
		top: 0px;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.banner-text-wrapper {
		width: 80%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: rgba(255, 255, 255, 0.664);
		padding: 30px;
		border-radius: 10px;
		display: flex;
		flex-direction: column;

		@media (max-width: 1250px) {
			padding: 20px;
		}

		@media (max-width: 500px) {
			width: 100%;
			align-items: center;
		}

		.banner-text_header {
			font-weight: 100;
			text-transform: uppercase;
			margin-bottom: 20px;
			font-size: 2.4rem;

			.company-name {
				font-weight: 500;
				color: #7c3c98;
			}

			@media (max-width: 1250px) {
				font-size: 1.4rem;
			}
		}

		.company-description {
			display: block;
			font-size: 1.1rem;
			letter-spacing: 1px;
			color: #000;

			@media (max-width: 1250px) {
				font-size: 0.9rem;
				display: inline;
			}
		}

		.learn-more-button {
			margin-top: 20px;
		}
	}
}
