// Footer.scss
.footer-container {
	// Your existing styles for .footer can be placed here
}

.social-icon {
	margin-right: 8px;
	font-size: 24px;
}

.social-icon svg {
	fill: #7c3c98;
	transition: fill 0.2s;

	&:hover {
		fill: #000000; // Change to your desired hover color
	}
}
