.testimonial-wrapper {
    margin: 5rem 4rem;

    .testimonial-label {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 500;
		color: #7c3c98;
        margin-bottom: 20px;
	}

    .testimonial-card {
        margin: 0 2rem;
    }
}