.service-wrapper {
	margin: 2rem;

	.services-card-wrapper {
		display: flex;
		gap: 20px;
		padding: 2rem;

        @media (max-width: 800px) {
			flex-direction: column;
		}
	}
}

.service-card {
	border-radius: 10px;

	.service-label {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 500;
		color: #7c3c98;
	}

	.service-description {
		margin-bottom: 10px;
	}
}
