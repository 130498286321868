.appbar-container {
	position: static !important;
	background-color: #fff !important;
	box-shadow: none !important;

	.business-information {
		display: flex;
		align-items: center;
		@media (max-width: 1250px) {
			display: none;
		}

		.business-icon {
			height: 50px;
			width: 50px;
		}

		.business-name {
			color: 'primary';
			margin: 0 1rem;
			display: flex;
			font-family: monospace;
			font-weight: 700;
			letter-spacing: 0.3rem;
			text-decoration: none;

			@media (max-width: 1250px) {
				display: none;
			}
		}
	}

	.mobile-wrapper {
		display: flex;
		flex-grow: 1;

		@media (min-width: 1250px) {
			display: none;
		}

		.business-icon {
			height: 50px;
			width: 50px;
		}
	}

	.mobile-business-icon {
		height: 40px;
		width: 40px;
		margin-right: 1rem;

		@media (min-width: 750px) {
			height: 50px;
			width: 50px;
		}

		@media (min-width: 1250px) {
			display: none;
		}
	}

	.mobile-business-name {
		margin-right: 1rem;
		display: flex;
		flex-grow: 1;
		font-family: monospace;
		font-weight: 700;
		letter-spacing: 0.3rem;
		text-decoration: none;
		font-size: 0.8rem;

		@media (min-width: 750px) {
			font-size: 1.2rem;
		}

		@media (min-width: 1250px) {
			display: none;
		}
	}

	.button-wrapper {
		display: flex;
		flex-grow: 1;
		gap: 10px;
		@media (max-width: 1250px) {
			display: none;
		}
	}
}
