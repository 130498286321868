.about-container {
	display: flex;
	width: 100%;

	@media (max-width: 800px) {
		flex-direction: column;
	}

	.banner-image-wrapper {
		width: 50%;

		@media (max-width: 800px) {
			width: 100%;
		}

		.about-image {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.about-text-wrapper {
		width: 50%;
		flex: 1;
		margin: 1rem 2rem;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@media (max-width: 800px) {
			align-items: center;
			width: 100%;
			margin: 0;
			padding: 0.5rem 2rem;
		}

		.about-header {
			margin-bottom: 10px;

			@media (max-width: 800px) {
				font-size: 20px;
			}
		}

		.about-icon {
			width: 20px;
			margin-right: 1rem;
			color: #ef799f;
		}

		.about-subheading {
			margin-left: 2.8rem;
			margin-bottom: 20px;

			@media (max-width: 800px) {
				font-size: 16px;
				margin: 0;
				font-weight: 600;
			}
		}

		.about-description {
			margin-left: 2.8rem;
			margin-bottom: 20px;

			@media (max-width: 800px) {
				font-size: 14px;
				margin: 10px 0;
			}
		}
	}
}
