* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	text-align: justify;
}

html,
body {
	display: flex;
	justify-content: center;
	margin: 0;
	padding: 0;
}

.layout {
	display: flex;
	flex-direction: column;
	width: 100vw;
	max-width: 2000px;
	outline: red 2px dashed;

	@media (max-width: 2000px) {
		max-width: 100%;
	}
}

/* Scrollbar Design  */

::-webkit-scrollbar {
	width: 20px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #7c3c98e3;
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #7c3c98;
}
